import { QueryStatus } from '@reduxjs/toolkit/query';
import { AddObjectButton } from 'components/addObjectButton';
import { ConditionalRender } from 'components/conditionalRenderer';
import { ErrorBox } from 'components/errorBox';
import { FullScreenLoadingSpinner } from 'components/fullScreenLoadingSpinner';
import { Button } from 'components/genericButton';
import { Modal } from 'components/modal';
import { PortfolioItemValueInput } from 'components/portfolioItemValueInput';
import { Select, SelectItem } from 'components/select';
import { isBefore, parse, parseISO } from 'date-fns';
import { useGtag } from 'hooks/useGtag';
import { useQueryParameters } from 'hooks/useQueryParameters';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addPortfolioItemItemSelector,
  addPortfolioItemModifiedSelector,
  reset,
  setItem,
  setItemType,
} from 'redux/reducers/addPortfolioItemFormReducer';
import { Page } from 'types/page';
import {
  PortfolioItemType,
  portfolioItemTypeLabels,
  PortfolioTableItem,
} from 'types/portfolioItem';
import { enumToSelectItems } from 'utils/enumToSelectItems';
import { isEmptyOrNullOrUndefined } from 'utils/isEmptyOrNullOrUndefined';
import { formatDate } from 'views/DataAnalysis/valuesFormatters';
import api, {
  useAddItemMutation,
  useAddValueMutation,
  useItemQuery,
  useUpdateItemMutation,
} from 'views/Portfolios/PortfoliosModal/api';
import { ItemDetailsFormSelector } from 'views/Portfolios/PortfoliosModal/itemDetailsFormSelector';

interface Props {
  readonly portfolioId: string;
  readonly relationshipId?: string;
  readonly itemId?: string;

  onClose(): void;
}

interface HistoricEntry {
  readonly currency: string;
  readonly value: number;
  readonly date: string;
}

export const PortfolioItemFormEditCreateForm: React.FC<Props> = ({
  portfolioId,
  itemId,
  onClose,
}: Props): React.ReactElement => {
  const dispatch = useDispatch<any>();
  const item = useSelector(addPortfolioItemItemSelector);
  const queryParameters = useQueryParameters();
  const modified = useSelector(addPortfolioItemModifiedSelector);

  const [addValueModalOpen, setAddValueModalOpen] = useState<boolean>(false);
  const [addItem, addItemMutation] = useAddItemMutation();
  const [addValue, addValueMutation] = useAddValueMutation();
  const [updateItem, updateItemMutation] = useUpdateItemMutation();
  const [closeAfterSave, setCloseAfterSave] = useState<boolean>(false);
  const { data: currentItem, isFetching } = useItemQuery(
    {
      portfolioId: portfolioId,
      itemId: itemId,
    },
    {
      skip: isEmptyOrNullOrUndefined(itemId),
    },
  );
  const { valueHistory } = currentItem || { valueHistory: [] };
  // TODO: use the addValueMutation to update the item value
  void addValueMutation;

  const { trackEvent } = useGtag();
  const mutation = React.useMemo((): any => {
    if (itemId) {
      return updateItemMutation;
    } else {
      return addItemMutation;
    }
  }, [addItemMutation, updateItemMutation, itemId]);

  useEffect((): VoidFunction => {
    return (): void => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleSave = useCallback((): void => {
    if (!itemId) {
      addItem({
        portfolioId: portfolioId,
        item: item,
      });

      trackEvent('added-portfolio-item', {
        item: item,
      });
    } else {
      setCloseAfterSave(true);
      updateItem({
        portfolioId: portfolioId,
        item: item,
      });

      trackEvent('updated-portfolio-item', {
        item: item,
      });
    }

    return;
  }, [addItem, item, itemId, portfolioId, trackEvent, updateItem]);

  const handleSaveAndClose = useCallback((): void => {
    setCloseAfterSave(true);
    handleSave();
  }, [handleSave]);

  useEffect((): void => {
    const { util } = api;
    if (!util) {
      console.warn('the `util` object is not defined on the api object');
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      dispatch(reset());

      dispatch(
        util.updateQueryData(
          'items',
          { ...queryParameters, portfolioId },
          (page: Page<PortfolioTableItem>): Page<PortfolioTableItem> => {
            const newItem = mutation.data;
            if (newItem === null) {
              return page;
            }

            const isSame = (item: PortfolioTableItem): boolean => {
              return item.id === newItem.id;
            };
            const sortFn = (a: PortfolioTableItem, b: PortfolioTableItem): number => {
              const { added_at: aAddedAt } = a;
              const { added_at: bAddedAt } = b;

              const aAddedAtDate = parse(aAddedAt, 'yyyy-MM-dd', new Date());
              const bAddedAtDate = parse(bAddedAt, 'yyyy-MM-dd', new Date());

              return isBefore(aAddedAtDate, bAddedAtDate) ? 1 : -1;
            };
            // Need to map the data to the PortfolioItem type
            return Page.addOrReplace<PortfolioTableItem>(page, newItem, isSame, sortFn);
          },
        ),
      );
    }
  }, [dispatch, itemId, mutation.data, mutation.status, portfolioId, queryParameters]);

  const handleSaveFn = useMemo((): VoidFunction | undefined => {
    if (modified) {
      return handleSave;
    }

    return undefined;
  }, [handleSave, modified]);

  const handleSaveAndCloseFn = useMemo((): VoidFunction | undefined => {
    if (modified) {
      return handleSaveAndClose;
    }

    return undefined;
  }, [handleSaveAndClose, modified]);

  useEffect((): void => {
    if (!closeAfterSave) {
      return;
    }

    if (mutation.status === QueryStatus.fulfilled) {
      onClose();
    }
  }, [closeAfterSave, mutation.status, onClose]);

  useEffect((): void => {
    if (currentItem) {
      dispatch(setItem(currentItem));
    }
  }, [currentItem, dispatch]);

  const handleItemTypeChange = useCallback(
    (value: PortfolioItemType): void => {
      dispatch(setItemType(value));
    },
    [dispatch],
  );

  const closeFailureMessage = useCallback((): void => {
    mutation.reset();
  }, [mutation]);

  const showSpinner = useMemo((): boolean => {
    if (isFetching) {
      return true;
    }

    return mutation.status === QueryStatus.pending;
  }, [isFetching, mutation.status]);

  const errorTitle = useMemo((): string => {
    if (itemId) {
      return 'There was a problem updating the portfolio item';
    } else {
      return 'There was a problem adding the item to the portfolio';
    }
  }, [itemId]);

  const isEditMode = useMemo((): boolean => !isEmptyOrNullOrUndefined(itemId), [itemId]);

  const openAddValueModal = React.useCallback((): void => {
    setAddValueModalOpen(true);
  }, []);

  const closeAddValueModal = React.useCallback((): void => {
    setAddValueModalOpen(false);
  }, []);

  const formClassName = useMemo((): string => {
    if (isEditMode) {
      return 'flex flex-col gap-2 flex-1 max-w-max min-w-max';
    } else {
      return 'flex flex-col gap-2 flex-1';
    }
  }, [isEditMode]);

  const handleSaveValue = useCallback(
    (event: React.FormEvent): void => {
      event.preventDefault();

      const { currentTarget: form } = event;
      if (form instanceof HTMLFormElement) {
        const elements = form.elements;
        const valueElement = elements.namedItem('value');
        if (valueElement instanceof HTMLInputElement) {
          const value = JSON.parse(valueElement.value);
          addValue({
            portfolioId: portfolioId,
            itemId: item.id,
            value: value,
          });
        } else {
          console.warn('The value element is not an instance of HTMLInputElement');
        }
      } else {
        console.warn('The form element is not an instance of HTMLFormElement');
      }
    },
    [addValue, item.id, portfolioId],
  );

  return (
    <>
      <div className="flex auto-flex">
        <div className={formClassName}>
          <Select
            items={portfolioItemTypes}
            value={item.type}
            label="Item Type"
            readOnly={isEditMode}
            onChange={handleItemTypeChange}
          />

          <form autoComplete="off">
            <div className="flex flex-col gap-2 flex-1">
              <ItemDetailsFormSelector item={item} />
            </div>
          </form>
        </div>

        <ConditionalRender renderIf={isEditMode}>
          <div className="flex flex-col flex-1 min-h-full border-l border-gray-medium ml-3 pl-3">
            <h4 className="py-2">Asset Performance</h4>
            <div className="flex flex-1">
              <div className="flex flex-col w-64">
                <table className="flex-1">
                  <thead>
                    <tr className="flex items-center px-2 py-1 text-xs gap-3">
                      <th className="flex-1 text-center uppercase">Date</th>
                      <th className="flex-1 text-center uppercase">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {valueHistory.map((entry: unknown): React.ReactElement => {
                      const { date, currency, value } = normalizeHistoryEntry(item.type, entry);
                      try {
                        const formatter = new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: currency,
                        });
                        return (
                          <tr
                            key={date}
                            className="flex items-center px-2 py-1 text-sm gap-3 border-b border-gray-medium"
                          >
                            <td className="flex-1">{formatDate(parseISO(date))}</td>
                            <td className="flex-1 text-right">{formatter.format(value)}</td>
                          </tr>
                        );
                      } catch {
                        return (
                          <tr>
                            <td />
                            <td />
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
                <div className="mt-10">
                  <AddObjectButton title="Value" onClick={openAddValueModal} />
                </div>
              </div>
            </div>
          </div>
        </ConditionalRender>
      </div>

      <div className="flex items-center gap-3 pt-12 bg-gray-light sticky bottom-0">
        <div className="mr-auto">
          <Button type="button" variant="secondary" label="Cancel" onClick={onClose} />
        </div>
        <ConditionalRender renderIf={isEditMode}>
          <Button
            type="button"
            variant="primary"
            label="Save"
            disabled={false}
            onClick={handleSaveFn}
          />
        </ConditionalRender>
        <ConditionalRender renderIf={!isEditMode}>
          <Button
            type="button"
            variant="secondary"
            label="Save & Add"
            disabled={false}
            onClick={handleSaveFn}
          />
          <Button
            type="button"
            variant="primary"
            label="Save & Close"
            disabled={false}
            onClick={handleSaveAndCloseFn}
          />
        </ConditionalRender>
      </div>
      <FullScreenLoadingSpinner visible={showSpinner} />

      <Modal isOpen={mutation.status === QueryStatus.rejected} onClose={closeFailureMessage}>
        <Modal.Content>
          <ErrorBox
            title={errorTitle}
            message="We are not sure what went wrong. Please try again. If the problem persists, please contact support."
            onClose={closeFailureMessage}
          />
        </Modal.Content>
      </Modal>

      <Modal isOpen={addValueModalOpen} onClose={closeAddValueModal}>
        <Modal.Content title="Add Value">
          <form onSubmit={handleSaveValue}>
            <div className="flex flex-col gap-2 w-modal-sm">
              <PortfolioItemValueInput item={item} />
              <div className="flex items-center justify-end mt-5">
                <Button type="submit" variant="primary" label="Add" />
              </div>
            </div>
          </form>
        </Modal.Content>
      </Modal>
    </>
  );
};

const portfolioItemTypes: Array<SelectItem<PortfolioItemType>> = enumToSelectItems(
  PortfolioItemType,
  portfolioItemTypeLabels,
);

const normalizeHistoryEntry = (itemType: PortfolioItemType, entry: any): HistoricEntry => {
  switch (itemType) {
    case PortfolioItemType.publicCompany:
      return {
        date: entry.date,
        currency: entry.averagePricePerShareCurrency,
        value: entry.numberOfShares * entry.averagePricePerShareValue,
      };
    case PortfolioItemType.privateCompany:
      return {
        date: entry.date,
        currency: entry.averagePricePerShareCurrency,
        value: entry.numberOfShares * entry.averagePricePerShareValue,
      };
    case PortfolioItemType.cash:
      break;
    case PortfolioItemType.cryptoCurrency:
      return {
        date: entry.date,
        currency: entry.currency,
        value: entry.quantity * entry.price,
      };
    case PortfolioItemType.etf:
      return {
        date: entry.date,
        currency: entry.averagePricePerShareCurrency,
        value: entry.numberOfShares * entry.averagePricePerShareAmount,
      };
    case PortfolioItemType.commodity:
      break;
    case PortfolioItemType.realEstate:
      break;
    case PortfolioItemType.art:
      break;
    case PortfolioItemType.other:
      break;
    case PortfolioItemType.invalid:
      break;
  }

  return {
    date: '2024-01-08',
    currency: 'USD',
    value: 100,
  };
};
